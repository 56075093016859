import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import ContactFormImg from "../images/contact-form-img.png";  
import CallIcon from "../assets/icons/call-icon.svg";
import ContactBuildingIcon from "../assets/icons/contact-building-icon.svg"; 
import Select from 'react-select'

const ContactPage = () => {
    const options = [
        { value: 'content', label: 'Content' },
        { value: 'discovery', label: 'Discovery' },
        { value: 'journey', label: 'Journey' },
        { value: 'complete-experro-product-suit', label: 'Complete Experro Product Suit' }
    ]
    return (
        <div className="template-contact header-white header-position">
            <Layout>
                  
                <div className="content-form-block contact-form mb-208">
                    <div className="container">
                        <div className="content-form-inner custom-row align-v-center row-2 d-flex">
                            <div className="content-block grid"> 
                                <div className="content-inner">
                                    <h3 className="h1 mb-24">See what Experro can do for you</h3>
                                    <p>Schedule 30-minutes of your time to learn more about how to upgrade your digital experiences for your shoppers and increase conversion rates with Experro.</p>
                                    <div className="image-block">
                                        <img loading="lazy" src={ContactFormImg} title="See what Experro can do for you" alt="See what Experro can do for you" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-block grid">
                                <div className="form-inner">
                                    <h4 className="link-color mb-40">Hello, Experro 👋</h4> 
                                
                                    <form>
                                        <div className="custom-row row-2 d-flex flex-wrap">
                                            <div className="grid mb-24 full">
                                                <input type="text" placeholder="First name*"></input>
                                            </div>
                                            <div className="grid mb-24 full">
                                                <input type="text" placeholder="Last name*"></input>
                                            </div>
                                            <div className="grid mb-24 full">
                                                <input type="text" placeholder="Company name*"></input>
                                            </div>
                                            <div className="grid mb-24 full">
                                                <input type="email" placeholder="Company email*"></input>
                                            </div>
                                            <div className="grid mb-24 full">
                                                <Select className="rect-select" placeholder="Product of interest*" options={options} />
                                            </div> 

                                            <div className="grid full mb-40">
                                                <input type="text" placeholder="What best describes you?"></input>
                                            </div>
 
                                            <div className="grid full">
                                                <input type="submit" className="button black-button" value="Get in Touch"></input>
                                            </div> 
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-details mb-208">
                    <div className="container">
                        <div className="contact-detail-inner d-flex flex-wrap">
                            <div className="conatct-address-block">
                                <div className="adress-title mb-48">
                                    <h3 className="d-flex font-medium"><span className="icon-block"><ContactBuildingIcon /></span>Our Offices</h3>
                                </div>
                                <ul>
                                    <li className="mb-48">    
                                        <span className="country-title small-text link-color font-capital">United States</span>
                                        <p className="font-light-color">Rapidops Inc.<br></br>
                                        1422 S Tryon St., Suite 300<br></br>
                                        Charlotte, NC 28203</p>
                                    </li>
                                    <li>    
                                        <span className="country-title small-text link-color font-capital">India</span>
                                        <p className="font-light-color">Rapidops Solutions Pvt. Ltd.<br></br>
                                        501-509 Satyamev Eminence<br></br>
                                        Science City Rd, Sola<br></br>
                                        Ahmedabad 380060</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="conatct-list-block">
                                <div className="conatct-list-title mb-48">
                                    <h3 className="d-flex font-medium"><span className="icon-block"><CallIcon /></span>Contact Us</h3>
                                </div>

                                <div className="conatct-lists mb-80">
                                    <span className="country-title small-text link-color font-capital">Email us</span>
                                    <ul className="d-flex flex-wrap">
                                        <li>
                                            <p class="font-medium font-dark-color">Media Inquiries</p>
                                            <Link className="font-light-color" to="mailto:media@experro.com">media@experro.com</Link>
                                        </li>
                                        <li>
                                            <p class="font-medium font-dark-color">Partner Inquiries</p>
                                            <Link className="font-light-color" to="mailto:partner@experro.com">partner@experro.com</Link>
                                        </li>
                                        <li>
                                            <p class="font-medium font-dark-color">Other Inquiries</p>
                                            <Link className="font-light-color" to="mailto:hello@experro.com">hello@experro.com</Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="conatct-lists mb-80">
                                    <span className="country-title small-text link-color font-capital">Call us</span>
                                    <ul className="d-flex flex-wrap">
                                        <li>
                                            <p class="font-medium font-dark-color">United States</p>
                                            <Link className="font-light-color" to="tel:1987-654-3210">+1 987-654-3210</Link>
                                        </li>
                                        <li>
                                            <p class="font-medium font-dark-color">India</p>
                                            <Link className="font-light-color" to="tel:91-98765-43210">+91 98765 43210</Link>
                                        </li> 
                                    </ul>
                                </div>

                            </div> 
                        </div>
                    </div>
                </div>

                
            </Layout>
        </div>
        
    );
}

export default ContactPage;